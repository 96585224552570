import { default as jsCookies } from 'js-cookie';
import { SnackbarProps } from '../components/Snackbar';

export const setDataToLocalStore = (
  field: string,
  data: string | number | boolean | Record<string, any> | any[],
  force: boolean = true,
) => {
  if (typeof window !== 'object' || (!force && window.localStorage.getItem(field))) {
    return false;
  }
  window.localStorage.setItem(field, JSON.stringify(data));
};

export const getDataFromLocalStore = (field: string) => {
  if (typeof window !== 'undefined') {
    const item = localStorage.getItem(field);
    return item ? JSON.parse(item) : null;
  }
};

const removeItemFromLocalStore = (field: string) => {
  window.localStorage.removeItem(field);
};

export const setCookie = (
  name: string,
  value: Exclude<any, Function>,
  options?: Record<string, string | number | boolean>,
) => {
  if (typeof window !== 'object') return;
  const preparedValue = typeof value === 'string' ? value : JSON.stringify(value);
  if (preparedValue.length > 4000) return;
  jsCookies.set(name, preparedValue, options);
};

export const getCookie = (name: string) => {
  const cookieString = jsCookies.get(name) || '';
  try {
    return JSON.parse(cookieString);
  } catch {
    return cookieString;
  }
};

export const removeCookie = (name: string) => {
  jsCookies.remove(name);
};

export const saveLogInDataOnBrowser = (token: string) => {
  setCookie('access_token', token, { expires: 30 });
  setDataToLocalStore('access_token', token);
};

export const removeLogInDataFromBrowser = () => {
  removeCookie('access_token');
  removeItemFromLocalStore('access_token');
};

export const storeFiltersOnBrowser = (filters: Record<string, Exclude<any, Function>>) => {
  if (typeof window !== 'object') return;
  setCookie('filters', filters, { path: document.location.pathname, expires: 30 });
};

export const storeTabOnBrowser = (tab: string) => {
  if (typeof window !== 'object') return;
  setCookie('tab', tab, { path: document.location.pathname, expires: 30 });
};

export const trackSessionRedirects = (router?: any, callback?: () => void) => {
  const redirect = sessionStorage.getItem('redirect');
  if (redirect) {
    sessionStorage.removeItem('redirect');
    if (router) {
      return router.push(redirect);
    } else if (typeof window === 'object') {
      return window.location.assign(redirect);
    }
  } else {
    callback && callback();
    return;
  }
};

export const saveSessionError = (errorText: string, detailText?: string) => {
  if (typeof window !== 'object') return;
  sessionStorage.setItem('error', errorText);
  detailText && sessionStorage.setItem('error-details', detailText);
};

export const saveSessionSuccess = (successText: string, detailText?: string) => {
  if (typeof window !== 'object') return;
  sessionStorage.setItem('success', successText);
  detailText && sessionStorage.setItem('success-details', detailText);
};

export const processSessionSuccess = (notificationCallback: (message: SnackbarProps) => void) => {
  const success = sessionStorage.getItem('success');
  const detail = sessionStorage.getItem('success-details');
  if (success) {
    sessionStorage.removeItem('success');
    detail && sessionStorage.removeItem('success-details');
    notificationCallback({
      mode: 'success',
      header: success,
      message: detail || '',
      period: 10000,
    });
    return;
  }
};

export const processSessionError = (notificationCallback: (message: SnackbarProps) => void) => {
  const error = sessionStorage.getItem('error');
  const detail = sessionStorage.getItem('error-details');
  if (error) {
    sessionStorage.removeItem('error');
    detail && sessionStorage.removeItem('error-details');
    notificationCallback({
      mode: 'error',
      header: error,
      message: detail || '',
      period: 30000,
    });
    return;
  }
};

export const checkIfShouldShowModal = (name: string) => {
  const isShoved = localStorage.getItem(name);
  if (isShoved) {
    return false;
  }
  localStorage.setItem(name, 'true');
  return true;
};

export const storeTableParamsOnBrowser = (tableName: string, params: Record<string, Exclude<any, Function>>) => {
  if (typeof window !== 'object') return;
  setCookie(`table-${tableName}`, params, { path: document.location.pathname, expires: 1 });
};

export const getTableParamsOnBrowser = (tableName: string) => {
  if (typeof window !== 'object') return {};
  return  getCookie(`table-${tableName}`);
};
