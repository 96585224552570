import { DefaultOgDataProps, OgData, ProductInstance } from 'types';
import { FACEBOOK_APP_PROD_ID, FACEBOOK_APP_DEV_ID } from 'consts';
import { capitalizeSentences } from './formatters';

export const getProductPageLdJson = (product: ProductInstance) =>
  `{
     "@context": "https://schema.org",
     "@type": "Product",
     "description": "${product.description}",
     "name": "${product.title}",
     "image": "${product.media?.images?.[0]}",
     "offers": {
       "@type": "Offer",
       "businessFunction": "${product.type}",
       "price": "${product.price}",
       "priceCurrency": "USD"
     }
  }`;

export const getHomePageLdJson = () =>
  `{
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "SENDY",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "720 Bohemian Hwy",
      "addressLocality": "Freestone",
      "addressRegion": "CA",
      "postalCode": "95472",
      "addressCountry": "US"
    },
    "telephone": "+1-707-339-1794",
    "description": "SENDY is an online marketplace and gear hub that enables community members to Buy, Sell, and Rent Adventure Sports equipment anywhere in North America. Brought to you by Adventure Sports icons Travis Rice and Cam Zink, the founding team of SENDY brings a deep understanding of the action sports community. Join us today and explore a marketplace like no other.",
    "slogan": "BUY - SELL - RENT - SEND",
    "image": "https://storage.googleapis.com/sendy-storage/static/sendy_default_banner.jpg"
  }`;

export const getCommonOgData = (props: DefaultOgDataProps): OgData[] => [
  {
    prop: 'url',
    content: props.url,
  },
  {
    prop: 'og:url',
    content: props.url,
  },
  {
    prop: 'type',
    content: props.type || 'website',
  },
  {
    prop: 'og:type',
    content: props.type || 'website',
  },
  {
    prop: 'title',
    content: props.title || 'Ready to get SENDY?',
  },
  {
    prop: 'og:title',
    content: props.title || 'Ready to get SENDY?',
  },
  {
    prop: 'description',
    content: props.description || descriptions.default,
  },
  {
    prop: 'og:description',
    content: props.description || descriptions.default,
  },
  {
    prop: 'image',
    content: props.banner || 'https://storage.googleapis.com/sendy-storage/static/sendy_default_banner.jpg',
  },
  {
    prop: 'og:image',
    content: props.banner || 'https://storage.googleapis.com/sendy-storage/static/sendy_default_banner.jpg',
  },
  {
    prop: 'fb:app_id',
    content: getFbAppId(),
  },
];

export const getFbAppId = () => {
  if (process.env.NODE_ENV !== 'production') {
    return FACEBOOK_APP_DEV_ID;
  }
  return FACEBOOK_APP_PROD_ID;
};

export const getPageDescription = (pageName: string, data?: object) => {
  let description: string | ((data?: object) => string) = descriptions[pageName] || descriptions.default;

  return typeof description === 'function'
    ? description(data)
    : description;
}

export const descriptions = {
  default: 'BUY IT, SELL IT, RENT IT! SENDY',
  error: 'SENDY is an online marketplace and Gear Hub that allows community members Buying, Selling, and Renting Adventure Sports equipment anywhere in North America.',
  signUp: 'Create your SENDY account to Buy, sell, Rent Adventure Sports equipment anywhere in North America. It is an online marketplace and Gear Hub for our users.',
  signIn: 'Sign-in to your SENDY account to Buy, sell, Rent Adventure Sports equipment anywhere in North America. It is an online marketplace and Gear Hub for our users.',
  resetPassword: 'You can reset your password to Buy, sell, Rent Adventure Sports equipment anywhere in North America. It is an online marketplace and Gear Hub for our users.',
  privacyPolicy: 'SENDY Privacy Policy. SENDY is an online marketplace that lets community members Buy, sell and Rent Adventure Sports equipment anywhere in North America.',
  termsOfUse: 'SENDY Terms of Use. SENDY is an online marketplace that lets community members Buy, sell and Rent Adventure Sports equipment anywhere in North America.',
  gear: ({ description }) => capitalizeSentences(description),
  searchGear: 'Buy, sell, and Rent Adventure Sports equipment anywhere in North America using SENDY. It is an online marketplace and Gear Hub for our community members.',
  searchUsers: 'Find a SENDY user. SENDY is an online marketplays that lets community members to Buy, sell, and Rent Adventure Sports equipment anywhere in North America.',
  user: ({ username }) => `See the SENDY profile of ${username}. SENDY is an online marketplace and Gear Hub that lets our community members equipment anywhere in North America.`,
  userGuide: 'User Guide of SENDY, an online marketplace and Gear Hub that lets community members Buy, sell and Rent Adventure Sports equipment anywhere in North America.',
  userGuideArticle: ({ description }) => description,
  faq: 'Frequently Asked Questions. SENDY is an online marketplace that lets community members Buy, sell and Rent Adventure Sports equipment anywhere in North America.',
  blog: 'SENDYpedia is a library for SENDY users, an online marketplace that allow Buying, Selling, and Renting Adventure Sports equipment anywhere in North America.',
  blogPost: ({ title, subtitle }) => `${title}${title[title.length - 1] === '.' ? '' : '.'} ${subtitle}`,
};

export const getPageH1Title = (pageName: string, data?: object) => {
  let title: string | ((data?: object) => string) = h1Titles[pageName];

  return typeof title === 'function'
    ? title(data)
    : title;
}

export const h1Titles = {
  error404: 'Error 404',
  signUp: 'Sign Up to SENDY',
  signIn: 'Sign In to SENDY',
  resetPassword: 'Reset your password',
  privacyPolicy: 'SENDY Privacy Policy',
  termsOfUse: 'SENDY: Terms of Use',
  gear: ({ title }) => title,
  searchGear: 'Find adventure sports equipment on SENDY',
  searchUsers: 'Find users on SENDY',
  user: ({ username }) => `${username} on SENDY`,
  userGuide: 'Guide to SENDY',
  userGuideArticle: ({ title }) => title,
  faq: 'Frequently Asked Questions',
  blog: 'SENDYpedia',
  blogPost: ({ title }) => title,
};
