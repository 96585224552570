import {
  AdminListRestParams,
  ComponentSelectorOption,
  DealTypeForTab,
  FeedEventType, GmcGearFileStatus,
  GmcGearStatus,
  InitialOrderStatusForProcessing,
  ItemManagementApi,
  MenuItemType,
  MessagesRoomType,
  OrderDealType,
  OrderDetailButton,
  OrderStatus,
  OrderType,
  ProductCondition,
  PurchaseApiType,
  SupportedCountry,
  SupportedEventNotificationKey,
  UserRegistrationStep,
} from 'types';

export const CACHE = {
  short: 60000, // one minute
  default: 3600000, // one hour
  long: 86400000, // one day
};

export const DEFAULT_DASHBOARD_QUERY: AdminListRestParams = {
  page: 1,
  limit: 10,
};

export const SIGN_UP_STEP_CAPTIONS = ['Phone Number', 'Phone Number', 'Account Type', 'Account Info', 'Location', 'Activities'];

export const SIGN_UP_STEP_CAPTION_MAP: Record<UserRegistrationStep, string> = {
  'verify-phone': 'Phone Number',
  'account-type': 'Account Type',
  'account-info': 'Account Info',
  location: 'Location',
  activities: 'Activities',
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC_Ra_C9xUX7z-VZK1hVFnkJQwsZjdL4j8';

export const PERMISSION_LIST = [
  'activities_view',
  'activities_edit',
  'admins_view',
  'admins_edit',
  'ambassadors_view',
  'ambassadors_edit',
  'brands_view',
  'brands_edit',
  'categories_view',
  'categories_edit',
  'colors_view',
  'colors_edit',
  'content_system_view',
  'content_system_edit',
  'customer_groups_view',
  'customer_groups_edit',
  'dashboard_view',
  'gears_management_view',
  'gears_management_edit',
  'markets_view',
  'markets_edit',
  'offers_view',
  'orders_view',
  'orders_edit',
  'order_disputes_view',
  'order_disputes_edit',
  'product_types_view',
  'product_types_edit',
  'reports_view',
  'reports_edit',
  'reported_orders_view',
  'reported_orders_edit',
  'report_reasons_view',
  'report_reasons_edit',
  'sizes_view',
  'sizes_edit',
  'support_tickets_view',
  'support_tickets_edit',
  'tags_view',
  'tags_edit',
  'users_view',
  'users_edit',
  'qbp_catalog_view',
  'qbp_catalog_edit',
  'lm_catalog_view',
  'lm_catalog_edit',
  'shopify_view',
  'shopify_edit',
];

export const ADMIN_PERMISSIONS = [
  {
    caption: 'Activities',
    prefix: 'activities_',
  },
  {
    caption: 'Admins',
    prefix: 'admins_',
  },
  {
    caption: 'Ambassadors',
    prefix: 'ambassadors_',
  },
  {
    caption: 'Brands',
    prefix: 'brands_',
  },
  {
    caption: 'Categories',
    prefix: 'categories_',
  },
  {
    caption: 'Colors',
    prefix: 'colors_',
  },
  {
    caption: 'Dashboard',
    prefix: 'dashboard_',
    disableEdit: true,
  },
  {
    caption: 'Gear Management',
    prefix: 'gears_management_',
  },
  {
    caption: 'Markets',
    prefix: 'markets_',
  },
  {
    caption: 'Order Disputes',
    prefix: 'order_disputes_',
  },
  {
    caption: 'Orders',
    prefix: 'orders_',
  },
  {
    caption: 'Other Settings',
    prefix: 'system_settings_',
  },
  {
    caption: 'Content Management',
    prefix: 'content_system_',
  },
  {
    caption: 'Reports',
    prefix: 'reports_',
  },
  {
    caption: 'Report Reasons',
    prefix: 'report_reasons_',
  },
  {
    caption: 'Sizes',
    prefix: 'sizes_',
  },
  {
    caption: 'Support Tickets',
    prefix: 'support_tickets_',
  },
  {
    caption: 'Tags',
    prefix: 'tags_',
  },
  {
    caption: 'Users',
    prefix: 'users_',
  },
  {
    caption: 'User Groups',
    prefix: 'customer_groups_',
  },
  {
    caption: 'Offers',
    prefix: 'offers_',
    disableEdit: true,
  },
  {
    caption: 'Messages',
    prefix: 'messages_',
  },
  {
    caption: 'QBP Catalog',
    prefix: 'qbp_catalog_',
  },
  {
    caption: 'LM Catalog',
    prefix: 'lm_catalog_',
  },
  {
    caption: 'Shopify',
    prefix: 'shopify_',
  },
];

export const ADMIN_PERMISSION_OPTIONS = [
  {
    caption: 'View',
    suffix: 'view',
  },
  {
    caption: 'Editing',
    suffix: 'edit',
  },
];

export const CARD_CAPTIONS_MAP = {
  visa: 'Visa',
  Visa: 'Visa',
  mastercard: 'MasterCard',
  discover: 'Discover',
  amex: 'American Express',
};

export const GEAR_CREATION_BASE_STEP_CAPTIONS: any[] = ['Deal Type'];

export const RENT_GEAR_CREATION_STEP_CAPTION_MAP: Record<ItemManagementApi, string> = {
  items: 'Items',
  'main-info': 'Configuration',
  'blocked-rent-dates': 'Blocked Dates',
  photo: 'Upload Media',
  description: 'Description',
  pricing: 'Review Variants',
  'additional-info': 'Additional Gear Information',
  package: 'Package Box Size',
  'available-dates': 'Blocked Dates',
  'cancellation-fee': 'Cancellation Fee',
  'schedule-publish': 'Scheduled Publication',
};

export const GEAR_CREATION_STEP_CAPTION_MAP: Record<ItemManagementApi, string> = {
  items: 'Items',
  'main-info': 'Configuration',
  'blocked-rent-dates': 'Blocked Dates',
  photo: 'Upload Media',
  description: 'Description',
  pricing: 'Pricing',
  'additional-info': 'Additional Gear Information',
  package: 'Package Box Size',
  'available-dates': 'Blocked Dates',
  'cancellation-fee': 'Cancellation Fee',
  'schedule-publish': 'Scheduled Publication',
};

export const GEAR_OPTIONS_CREATION_STEP_CAPTION_MAP: Record<ItemManagementApi, string> = {
  items: 'Items',
  'main-info': 'Configuration',
  'blocked-rent-dates': 'Blocked Dates',
  photo: 'Upload Media',
  description: 'Description',
  'additional-info': 'Additional Gear Information',
  pricing: 'Review Variants',
  package: 'Package Box Size',
  'available-dates': 'Blocked Dates',
  'cancellation-fee': 'Cancellation Fee',
  'schedule-publish': 'Scheduled Publication',
};

export const RENT_GEAR_CREATION_STEPS: ItemManagementApi[] = [
  'main-info',
  'photo',
  'description',
  'additional-info',
  'pricing',
  'available-dates',
  'cancellation-fee',
];

export const SELL_GEAR_CREATION_STEPS: ItemManagementApi[] = [
  'main-info',
  'photo',
  'description',
  'pricing',
  'additional-info',
  'package',
  'schedule-publish',
];

export const GEAR_CREATION_STEPS: Record<OrderDealType, ItemManagementApi[]> = {
  rent: RENT_GEAR_CREATION_STEPS,
  sell: SELL_GEAR_CREATION_STEPS,
};

export const FEED_TABS: DealTypeForTab[] = ['sell', 'rent'];
export const FEED_TAB_CAPTIONS: Record<DealTypeForTab, string> = { sell: 'BUY', rent: 'RENT', all: 'ALL' };

export const USER_PHOTO_PLACEHOLDER_LINK = 'https://storage.googleapis.com/sendy-storage/static/user_photo.png';
export const USER_COVER_PLACEHOLDER_LINK = 'https://cdn.sendy.io/static/user_cover.png';
export const USER_PHOTO_PLACEHOLDER_MONOCHROME_LINK = '/avatar_monochrome.svg';
export const USER_BACKGROUND_PLACEHOLDER_LINK = '/background.svg';

export const APPSTORE_APP_LINK = 'https://apps.apple.com/ua/app/sendy-sell-it-rent-it/id1620902414';

export const GOOGLE_PLAY_APP_LINK = 'https://play.google.com/store/apps/details?id=com.sendy';

export const FACEBOOK_APP_PROD_ID = '4776790499100749';

export const FACEBOOK_APP_DEV_ID = '4776790499100749'; // TEMPORARY

export const SUPPORTER_EVENT_UPDATE_FIELDS: SupportedEventNotificationKey[] = [
  'new_orders',
  'new_offers',
  'unread_messages_count',
];

export const ALL_ITEM_STATUSES = [
  'success',
  'canceled',
  'failed',
  'shipping_waiting',
  'in_transit',
  'delivered',
  'return_shipping_waiting',
  'return_delivered',
  'return_picked_up_delivery',
  'return_in_transit',
  'returned',
  'renting_scheduled',
  'renting_process',
  'renting_overdue',
  'renting_overdue_canceled',
];

export const SELL_STATUSES = [
  'shipping_waiting',
  'in_transit',
  'delivered',
  'success',
  'return_shipping_waiting',
  'return_in_transit',
  'return_delivered',
  'returned',
  'canceled',
  'failed',
];

export const RENT_ITEM_STATUSES = [
  'renting_scheduled',
  'renting_process',
  'success',
  'renting_overdue',
  'renting_overdue_canceled',
  'canceled',
  'failed',
];

export const ORDER_DETAIL_LEFT_CONTROLS: Record<
  OrderType,
  Record<OrderDealType, Record<OrderStatus, OrderDetailButton[]>>
> = {
  purchase: {
    sell: {
      shipping_waiting: ['orderChat', 'report'],
      renting_scheduled: [],
      in_transit: ['orderChat', 'report'],
      delivered: ['orderChat', 'confirmComplete', 'return', 'report'],
      renting_process: [],
      renting_overdue: [],
      success: ['orderChat', 'writeReview', 'archive', 'report'],
      canceled: ['orderChat', 'archive'],
      renting_overdue_canceled: ['orderChat', 'archive'],
      return_shipping_waiting: ['getShippingLabel', 'writeReview', 'orderChat', 'report'],
      return_in_transit: ['getShippingLabel', 'writeReview', 'orderChat', 'report'],
      return_delivered: ['getShippingLabel', 'writeReview', 'orderChat', 'report'],
      return_picked_up_delivery: ['getShippingLabel', 'writeReview', 'orderChat', 'report'],
      returned: ['getShippingLabel', 'writeReview', 'orderChat', 'report'],
      failed: ['orderChat', 'archive'],
    },
    rent: {
      shipping_waiting: [],
      renting_scheduled: ['cancelOrder', 'orderChat', 'startRentingRenter'],
      in_transit: [],
      delivered: [],
      renting_process: ['orderChat', 'endRentingRenter'],
      renting_overdue: ['orderChat', 'endRentingRenter'],
      success: ['writeReview', 'orderChat', 'archive'],
      canceled: ['orderChat', 'archive'],
      renting_overdue_canceled: ['orderChat', 'archive'],
      return_shipping_waiting: [],
      return_in_transit: [],
      return_delivered: [],
      return_picked_up_delivery: [],
      returned: [],
      failed: ['orderChat', 'archive'],
    },
  },
  sell: {
    sell: {
      shipping_waiting: ['cancelOrder', 'getShippingLabel', 'orderChat'],
      renting_scheduled: [],
      in_transit: ['getShippingLabel', 'orderChat'],
      delivered: ['getShippingLabel', 'orderChat'],
      renting_process: [],
      renting_overdue: [],
      success: ['writeReview', 'orderChat', 'archive'],
      canceled: ['orderChat', 'archive'],
      renting_overdue_canceled: [],
      return_shipping_waiting: ['orderChat', 'report'],
      return_in_transit: ['orderChat', 'report'],
      return_delivered: ['orderChat', 'report'],
      return_picked_up_delivery: ['orderChat', 'report'],
      returned: ['writeReview', 'orderChat', 'archive', 'report'],
      failed: ['orderChat', 'archive'],
    },
    rent: {
      shipping_waiting: [],
      renting_scheduled: ['orderChat', 'startRentingOwner'],
      in_transit: [],
      delivered: [],
      renting_process: ['orderChat', 'endRentingOwner'],
      renting_overdue: ['orderChat', 'endRentingOwner'],
      success: ['writeReview', 'orderChat', 'archive'],
      canceled: ['orderChat', 'archive'],
      renting_overdue_canceled: ['orderChat', 'archive'],
      return_shipping_waiting: [],
      return_in_transit: [],
      return_delivered: [],
      return_picked_up_delivery: [],
      returned: [],
      failed: ['orderChat', 'archive'],
    },
  },
};

export const SELL_STEPS: PurchaseApiType[] = ['shipping', 'payment-method', 'confirm'];
export const RENT_STEPS: PurchaseApiType[] = ['shipping', 'renting', 'payment-method', 'confirm'];

export const IMAGE_SIZE_LIMIT = 5000000; // in bites
export const VIDEO_SIZE_LIMIT = 50000000; // in bites

export const TOKEN_PROTECTED_URLS = ['/auth/user', '/register/profile', '/events/counters'];

export const STRIPE_TEST_KEY =
  'pk_test_51KjThrH5XhUauN5Ir1XruA2Cyyeia4qH4ZWvRn03V4rgOK5TQjBAK5nTHrIE0MDVV7R6h5yCp8hl3ET4g9RCs9z200iJnH2xRt';

export const CONDITION_OPTIONS = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'like_new',
    label: 'Like New',
  },
  {
    value: 'very_good',
    label: 'Very Good',
  },
  {
    value: 'good',
    label: 'Good',
  },
  {
    value: 'acceptable',
    label: 'Acceptable',
  },
];

export const GMC_ITEM_STATUSES: Array<GmcGearStatus> = ['archived', 'sold', 'inactive', 'active'];
export const GMC_ITEM_FILE_STATUSES: Array<GmcGearFileStatus> = ['display', 'hidden'];

export const PRODUCT_CONDITIONS_LIST: ProductCondition[] = ['like_new', 'very_good', 'good', 'acceptable'];

export const PRODUCT_CONDITIONS_MAP: Record<ProductCondition, string> = {
  new: 'New',
  like_new: 'Like New',
  very_good: 'Very Good',
  good: 'Good',
  acceptable: 'Acceptable',
};

export const SYSTEM_CHAT_TYPES: MessagesRoomType[] = [
  'cm_room_system',
  'cm_room_support_ticket',
  'cm_room_order_dispute',
];

export const MENU_USER_ITEMS: Array<MenuItemType> = [
  'feed',
  'sales',
  'purchases',
  'offers',
  'messages',
  // 'create',
  'gear',
  'rentalDashboard',
  // 'profile',
  'favorites',
  'balance',
  'support',
  'settings',
  'logout',
  'divider',
  'titleUseful',
  'guide',
  'faq',
  'blog',
  // 'contact',
  // 'divider',
  // 'titlePartners',
  // 'affiliate',
  // 'ambassadors',
  'divider',
  'titleTutorials',
  'buyTutorial',
  'sellTutorial',
  'rentTutorial',
  'divider',
  'titleAbout',
  'crowdfunding',
  'standWithUkraine',
  'story',
  'retail',
  'media',
  'divider',
  // 'snowboard',
  // 'skis',
  // 'divider',
];

export const MENU_GUEST_ITEMS: Array<MenuItemType> = [
  'contact',
  'guide',
  'faq',
  'blog',
  // 'affiliate',
  // 'ambassadors',
  'divider',
  'titleTutorials',
  'buyTutorial',
  'sellTutorial',
  'rentTutorial',
  'divider',
  'titleAbout',
  'crowdfunding',
  'standWithUkraine',
  'story',
  'retail',
  'media',
  'divider',
  // 'snowboard',
  // 'skis',
];

export const MENU_ADMIN_ITEMS: Array<MenuItemType> = ['settings', 'logout'];

export const FEED_EVENT_TYPE_MAP: Record<FeedEventType, string> = {
  post: 'Posted',
  share: 'Shared',
  price_change: 'Price updated',
};

export const componentSelectorOptions: ComponentSelectorOption = [
  { id: 'badge', title: 'Badge' },
  { id: 'banner', title: 'Banner' },
  { id: 'contactForm', title: 'Contact Form' },
  { id: 'heroSection', title: 'Hero Section' },
  { id: 'image', title: 'Image' },
  { id: 'paragraph', title: 'Paragraph' },
  { id: 'textImage', title: 'Text & Image' },
  { id: 'video', title: 'Video' },
  { id: 'downloadApp', title: 'Download App' },
];

export const SUPPORTED_COUNTRIES_LIST: SupportedCountry[] = ['US', 'USA', 'CA', 'CAN'];

export const COUNTRY_CURRENCY_MAP: Record<SupportedCountry, string> = {
  US: 'usd',
  USA: 'usd',
  CA: 'cad',
  CAN: 'cad',
};

export const PROCESSABLE_ORDER_STATUSES: Array<InitialOrderStatusForProcessing | OrderStatus> = [
  'shipping_waiting',
  'in_transit',
  'delivered',
  'return_shipping_waiting',
  'return_in_transit',
  'return_delivered',
];

export const DEAL_TYPE_OPTIONS = [
  { id: null, title: 'All' },
  { id: 'sell', title: 'Sell' },
  { id: 'rent', title: 'Rent' },
];
